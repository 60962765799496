<script>
import AuthenticationCode from "@/components/authentication-code";
import {userService} from "@/helpers/fakebackend/user.service";
import {requestMessage} from "@/helpers/request-message";
import Swal from "sweetalert2";
export default {
  name: "email-verify",
  components: {AuthenticationCode},
  data() {
    return {
      email: "",
      submitted: false,
      regError: "",
      tryingToRegister: false,
      isRegisterError: false,
      registerSuccess: false,
      step: 1,
      password: "",
      confirmPassword: "",
      code: "",
      token: "",
      isUserLogged: false,
    };
  },
  created() {
    document.body.classList.add("auth-body-bg");
    const user = JSON.parse(localStorage.getItem("user"));
    const userInfos = JSON.parse(localStorage.getItem("user_infos"));
    this.isUserLogged = (user && user.uuid && userInfos && userInfos.email);
    this.autocompleteCode();
    if(this.isUserLogged)
      this.email = userInfos.email;
  },
  methods: {
    autocompleteCode() {
      const params = this.paramsToJson(window.location.href);
      if (Object.keys(params).length >= 1) {
        if (params.email)
          this.email = params.email;
        if (params.otccode && params.email) {
          const paramCode = `${parseInt(params.otccode)}`;
          if (!isNaN(parseInt(paramCode)) && paramCode.length === 6) {
            this.code = paramCode;
            this.verifyAuthenticationcode();
          }
        }
      }
    },
    paramsToJson(url) {
      const params = unescape(url).split("?")[1]?.split("&");
      let objectParams = {};
      params?.forEach((param) => {
        const _param = param.split("=");
        objectParams[_param[0]] = _param[1];
      });
      return objectParams;
    },
    updateCode(code){
      this.code = code;
      if (code.length === 6 && this.email)
        this.verifyAuthenticationcode();
    },
    resendAuthenticationcode() {
      if (!this.email){
        this.isRegisterError = true;
        this.submitted = false;
        this.regError = "Geben Ihre eine E-Mail-Adresse ein";
        return;
      }
      this.submitted = true;
      this.isRegisterError = false;
      let request = null;
      if(this.isUserLogged)
        request = userService.verifyEMailResendCode(this.email)
      else
        request = userService.notloggedverifyEMailResendCode(this.email)
      request.then((data)=>{
        this.submitted = false;
        this.clearError();
        Swal.fire(requestMessage[data.code].de, "", "success")
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      });
    },
    verifyAuthenticationcode(){
      this.submitted = true;
      if (this.code.length !== 6){
        this.isRegisterError = true;
        this.regError = "Geben Sie einen gültigen Code ein";
        this.submitted = false;
        return;
      }
      let request = null;
      if(this.isUserLogged)
        request = userService.userVerifyEmail(this.code, this.email)
      else
        request = userService.notloggedUserVerifyEmail(this.code, this.email)
      request.then((data)=>{
        this.submitted = false;
        this.clearError();
        this.token = data.content.auth_token;
        Swal.fire("Erfolgreiche Validierung", "", "success");
        if(this.isUserLogged) {
          localStorage.setItem("user_infos", JSON.stringify(data.content));
          window.location.assign("/profile");
        }else
          this.$router.push({name: "home"})
      }).catch((data)=>{
        this.submitted = false;
        this.isRegisterError = true;
        this.regError = requestMessage[data.code].de??"Ein Fehler ist aufgetreten";
      });
    },
    clearError(){
      this.isRegisterError = false;
      this.regError = "";
    },
  }
}
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content d-flex min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">

                  <div v-if="!registerSuccess" class="col-lg-9">
                    <div>
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/logo-dark.svg" height="80" alt="logo" />
                        </a>
                      </div>

                      <div class="mt-5">
                        <h3 class="font-size-20 mb-4 font-weight-bold">Validierung der E-Mail-Adresse</h3>

                        <b-alert
                            v-model="isRegisterError"
                            class="mt-3"
                            variant="danger"
                            dismissible
                        >{{regError}}</b-alert>

                        <b-overlay :show="submitted">
                          <form class="form-horizontal" v-on:submit="(e)=>e.preventDefault()">
                            <div class="form-group auth-form-group-custom mb-4" v-show="!isUserLogged">
                              <input
                                  v-model="email"
                                  type="email"
                                  required
                                  class="form-control"
                                  id="email"
                                  placeholder="E-Mail-Adresse"
                              />
                            </div>
                            <div class="form-group auth-form-group-custom mb-4">
                              <h4 class="font-size-18 mt-4 font-weight-bold">Code einfügen</h4>
                              <p>Ein sechsstelliger Code wurde an Ihre Email-Adresse gesendet.</p>
                              <authentication-code :digit="6" :value="code" v-on:code-change="updateCode"></authentication-code>
                              <div class="mt-2">Haben den Code nicht bekommen ? <a href="" class="underline-primary" @click="(e) => { e.preventDefault(); resendAuthenticationcode()}">Code erneut senden</a></div>
                            </div>
                            <div>
                              <button
                                  class="btn btn-primary w-md waves-effect waves-light"
                                  type="button" @click="verifyAuthenticationcode"
                              >Validieren</button>
                            </div>
                          </form>
                        </b-overlay>
                      </div>
                    </div>
                  </div>

                  <div v-if="registerSuccess" class="col-lg-9">
                    <div>
                      <div>
                        <a href="/" class="logo">
                          <img src="@/assets/images/logo-dark.svg" height="80" alt="logo" />
                        </a>
                      </div>
                    </div>
                    <b-alert
                        v-model="registerSuccess"
                        class="mt-3"
                        variant="success">
                      Die Registrierung wurde erfolgreich abgeschloßen. Sie werden jetzt in Ihr Dashboard weitergeleitet.
                    </b-alert>
                    <div>
                      <router-link
                          tag="a"
                          to="/login"
                          class="font-weight-medium text-primary"
                      >Zurück zum Einloggen</router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg ml-auto">
              <div class="p-4 text-white">
                <h1 class="text-primary ml-4">E-Mail-Adresse <span class="text-white">Validieren</span></h1>
                <div class="underline-primary  ml-4 mt-2 mb-4" style="width: 200px"></div>
                <div class="content">
                  <div class="row">
                    <div class="col-8">
                      <img src="@/assets/images/icons/team.png" alt="Team">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

</style>