<template>
<div class="d-flex digits">
  <div v-for="(digit, i) in digits" v-bind:key="i" class="mr-2">
    <input type="text" :value="digit" :id="'digit'+i" @input="(e) => setDigit(i, e)" maxlength="1" class="digit form-control" autocomplete="xxx">
  </div>
</div>
</template>

<script>
export default {
  name: "authentication-code",
  data() {
    return {
      digits: [],
    }
  },
  created() {
    this.digits = new Array(this.digit);
    this.initDigits();
  },
  mounted() {
    document.querySelector("#digit0").focus();
    document.querySelector("body").addEventListener('paste', (event) => {
      this.clearDigits();
      const paste = (event.clipboardData || window.clipboardData).getData('text');
      let code = paste.replaceAll("-", "").replaceAll(" ", "");
      code = isNaN(parseInt(code))? "" : "" + parseInt(code);
      for(let i=0; i<this.digits.length; i++){
        this.digits[i] = code[i]??"";
        document.querySelector("#digit"+i).value = code[i]??"";
        this.$emit("code-change", this.digits.join(""));
      }
    });
  },
  methods: {
    initDigits(){
      if (this.value){
        for(let i=0; i < (this.value+"").length; i++){
          this.digits[i] = this.value[i];
          if (i === 5)
            break;
        }
      }
    },
    clearDigits(){
      // set all digits to empty string
      this.digits = new Array(this.digits.length);
    },
    setDigit(i, event){
      // return empty when body on paste event
      if (event.data === null)
        return;
      const digit = "" + parseInt(event.target.value);
      if (isNaN(digit)){
        event.target.value = "";
        this.digits[i] = "";
        this.$emit("code-change", this.digits.join(""));
        return;
      }
      event.target.value = digit;
      this.digits[i] = event.target.value==="" ? "" : digit;
      const nextFocusId = i === this.digits.length -1 ? 0 : ++i;
      if(event.data)
        document.querySelector("#digit"+nextFocusId).focus();
      this.$emit("code-change", this.digits.join(""));
    }
  },
  props: ["digit", "value"],
}
</script>

<style scoped>
.digit{
  padding: 0.47rem 0.75rem;
  text-align: center;
}
</style>